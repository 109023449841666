export default {
  Dashboards: '仪表板',
  CRM: '客户关系管理',
  Analytics: '分析',
  llm: '数据总览',
  eCommerce: '电子商务',
  Calendar: '日历',
  Chat: '聊天',
  Email: '电子邮件',
  Invoice: '发票',
  List: '列表',
  Preview: '预览',
  Edit: '编辑',
  Add: '添加',
  User: '用户',
  'User List': '用户列表',
  'User View': '用户视图',
  'APPS AND PAGES': '应用程序和页面',
  Pages: '页面',
  Authentication: '身份验证',
  'Login V1': '登录V1',
  'Login V2': '登录V2',
  'Register V1': '注册V1',
  'Register V2': '注册V2',
  'Forgot Password V1': '忘记密码V1',
  'Forgot Password V2': '忘记密码V2',
  'Reset Password V1': '重置密码V1',
  'Reset Password V2': '重置密码V2',
  Miscellaneous: '杂项',
  'Under Maintenance': '维护中',
  Error: '错误',
  'Coming Soon': '即将推出',
  'Not Authorized': '未授权',
  'Knowledge Base': '知识库',
  'Account Settings': '账户设置',
  Pricing: '定价',
  FAQ: '常见问题解答',
  ' ': '用户界面',
  Typography: '版式',
  Icons: '图标',
  Gamification: '游戏化',
  Cards: '卡片',
  Basic: '基本',
  Statistics: '统计',
  Advance: '提前',
  Actions: '行动',
  Chart: '图表',
  Components: '组件',
  Alert: '警报',
  Avatar: '阿凡达',
  Badge: '徽章',
  Button: '按钮',
  Menu: '菜单',
  'Expansion Panel': '扩展面板',
  Dialog: '对话框',
  Pagination: '分页',
  Tabs: '标签',
  Tooltip: '工具提示',
  Chip: '芯片',
  Snackbar: '小吃栏',
  Stepper: '步进器',
  Timeline: '时间轴',
  Treeview: '树视图',
  'FORMS & TABLES': '表格和表格',
  'Forms Elements': '表单元素',
  Autocomplete: '自动完成',
  Checkbox: '复选框',
  Combobox: '组合框',
  'File Input': '文件输入',
  Radio: '广播',
  'Range Slider': '范围滑块',
  Select: '选择',
  Slider: '滑块',
  Switch: '开关',
  Textarea: '文本区域',
  Textfield: '文本字段',
  'Date Picker': '日期选择器',
  'Month Picker': '月份选择器',
  'Time Picker': '时间选择器',
  Rating: '评级',
  'Form Layout': '表单布局',
  'Form validation': '表单验证',
  Tables: '表格',
  'Simple Table': '简单表',
  Datatable: '数据表',
  'CHARTS AND MAPS': '图表和地图',
  Charts: '图表',
  'Apex Chart': '顶点图',
  Chartjs: 'Chartjs',
  'Leaflet Maps': '传单地图',
  Others: '其他',
  'Menu Levels': '菜单级别',
  'Menu Level 2.1': '菜单级别 2.1',
  'Menu Level 2.2': '菜单级别 2.2',
  'Menu Level 3.1': '菜单级别 3.1',
  'Menu Level 3.2': '菜单级别 3.2',
  'Disabled Menu': '禁用菜单',
  'Raise Support': '提高支持',
  Documentation: '文档',
  'Charts & Maps': '图表和地图',
  Apps: '应用程序',
  'User Interface': '用户界面',
  'Forms & Tables': '表格和表格',
  Ques: '问答管理',
  ChatMan: '聊天管理',
  Model: '模型管理',
  task: {
    listTitle: '任务列表',
    addTask: '添加任务',
    name: '名称',
    status: '状态',
    robot: '应用',
    llm: '模型',
    cycle: ' 周期',
    time: '时间',
    score: '分数',
    addTaskTip1: '上传测试集',
    addTaskTip2: '系统将自动为你测试模型',
    testSet: '测试集',
    temperature: '温度',
    maxTokens: '最大输出长度(Token)',
    topP: 'top_p',
    frequencyEnalty: '频率损失',
    presencePenalty: '状态惩罚',
    noBegin: '未开始',
    loading: '进行中',
    success: '成功',
    error: '失败',
    warning: '告警',
    needExecuteCron: '开关',
    enterprise: '企业',
    filePreview: '文件预览',
    modelMan: '模型管理',
  },
  system: {
    noFound: '没找到页面',
    noFoundTip: '抱歉,找不到你要查找的页面。',
    backGoHome: '返回主页',
    searchPlaceHolder: '搜索',
    submit: '提交',
    cancel: '取消',
    export: '导出',
    reset: '重置',
    action: '操作',
    startDate: '开始日期',
    endDate: '结束日期',
    mobile: '手机号',
    appId: '应用ID',
    userId: '用户ID',
  },
}
