/* eslint-disable prefer-promise-reject-errors */
// axios
import { assign } from '@/plugins/utils'
import store from '@/store/index'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: '',

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
export const getDefaultHeaders = () => ({
  'X-Enterpriseid': store.state.common.enterprise || '',
  'X-Appid': store.state.common.robotInfo.appId || '',
  index_uid: store.state.common.envs.INDEX_ID || '',
  'X-App-Uid': store.state.common.robotInfo.appId || '',
  'X-Index-Uid': store.state.common.envs.INDEX_ID || '',
  'X-Run-Env': store.state.common.envs.RUN_ENV,
  'X-Userid': store.state.common.userInfo.userId || '',
  'X-Locale': localStorage.getItem('locale') || 'zh',

  Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxNzcxMTExMTExMSIsIm5iZiI6MTY5NjY0OTMyMCwiaXNzIjoic2Fhcy1hdXRoIiwidW5pYXV0aCI6eyJkaXNwbGF5X25hbWUiOiIxNzcxMTExMTExMSIsInBob25lIjoiMTc3MTExMTExMTEiLCJzdGF0dXMiOjEsInRlbmFudElkIjoxLCJ0eXBlIjoyLCJ1c2VyX2lkIjoiMTY4NjMzMDg2OTUxMjEyNjQ2NiIsInVzZXJfbmFtZSI6IjE3NzExMTExMTExIn0sImV4cCI6MTY5OTI0MTMyMH0.m5OP-USaYLdF64m1h0VVsTzU3rUeHuIXPAjHxutaPcE' || '',
  token: store.state.common.token || '',
})
axiosIns.interceptors.request.use(
  config => {
    const headers = getDefaultHeaders()
    assign(config.headers, headers)

    return config
  },
  error => Promise.reject(error),
)
axiosIns.interceptors.response.use(response => {
  const { data } = response
  const {
    code, status, errno,
  } = data
  console.log('333', response)
  if (code === 0 || status === 0 || status === 'success' || status === 200 || code === 200 || errno === 0 || errno === 200) {
    return response

    // 这后端数据结构是统一不了了，懒得掰扯
  // eslint-disable-next-line no-else-return
  } else if (
    [
      '/dataService/dashboard/overview',
      '/dataService/dashboard/data_usage',
      '/api/customize/accountCountAndAppCountAndTokenCost',
      '/apiService/api/customize/accountCountAndAppCountAndTokenCost',
    ].includes(response.config.url)
  ) {
    return response
  }

  return Promise.reject({
    response: {
      status: data.code,
      statusText: data.message || data.ret_msg || data.msg,
    },
  })
}, error => {
  if (error?.response?.status === 401) { // @:鉴权失败
  }

  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
