export default {
  namespaced: false,
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
    envs: {
      INDEX_ID: 'kkbot-index',
      RUN_ENV: 'dev',
    },
    robotInfo: {},
    loginInfo: {},
    token: localStorage.getItem('kkbotDataToken') || '',
    enterpriseInfo: JSON.parse(localStorage.getItem('enterpriseInfo') || '{}'),
    enterprise: localStorage.getItem('enterprise') || '',
    configs: [],
    robotList: [],
  },
  getters: {},
  mutations: {
    updateToken(state, info) {
      state.token = info
    },
  },
  actions: {},
}
