import {
  mdiAccountOutline,
  mdiForumOutline,
  mdiLandRowsHorizontal, mdiMessageQuestionOutline
} from '@mdi/js'

let result = [{
  title: 'User',
  icon: mdiAccountOutline,  
  children: [
    {
      title: 'User List',
      to: 'manage-user-list',
    },
  ],
}]
if (process.env.NODE_ENV === 'development') {
  result.push(...[
    {
      subheader: 'KKBot',
    },
    {
      icon: mdiMessageQuestionOutline,
      title: 'Ques',
      to: 'manage-ques',
    },
    {
      icon: mdiForumOutline,
      title: 'ChatMan',
      to: 'manage-chat',
    },
    {
      icon: mdiLandRowsHorizontal,
      title: 'Model',
      to: 'manage-model',
    },
  ]
  )
}
export default result
