import appAndPages from './app-and-pages'
import chartsAndMaps from './charts-and-maps'
import dashboard from './dashboard'
import formsAndTables from './forms-and-tables'
import kkbot from './kkbot'

import others from './others'
import uiElements from './ui-elements'

// eslint-disable-next-line import/no-mutable-exports
let result = []
if (process.env.NODE_ENV === 'development') {
  result = [...dashboard, ...kkbot, ...appAndPages, ...uiElements, ...formsAndTables, ...chartsAndMaps, ...others]
} else {
  result = [...dashboard, ...kkbot]
}
export default result
