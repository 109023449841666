const apps = [
  {
    path: '/manage/ques',
    name: 'manage-ques',
    component: () => import('@/views/manage/ques.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manage/chat',
    name: 'manage-chat',
    component: () => import('@/views/manage/chat.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manage/model',
    name: 'manage-model',
    component: () => import('@/views/manage/model/modelList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manage/user/list',
    name: 'manage-user-list',
    component: () => import('@/views/manage/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default apps
