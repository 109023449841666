import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    badge: '',
    badgeColor: 'warning',
    children: process.env.NODE_ENV === 'development' ? [
      {
        title: 'CRM',
        to: 'dashboard-crm',
      },
      {
        title: 'Analytics',
        to: 'dashboard-analytics',
      },
      {
        title: 'eCommerce',
        to: 'dashboard-eCommerce',
      },
      {
        title: 'llm',
        to: 'dashboard-llm',
      },
    ] : [{
      title: 'llm',
      to: 'dashboard-llm',
    }],
  },
]
