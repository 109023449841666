export default {
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  llm: 'Dashboard',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
  Ques: '问答管理',
  ChatMan: '聊天管理',
  Model: '模型管理',
  task: {
    listTitle: '任务列表',
    addTask: '添加任务',
    name: '名称',
    status: '状态',
    robot: '应用',
    llm: '模型',
    cycle: ' 周期',
    time: '时间',
    score: '分数',
    addTaskTip1: '上传测试集',
    addTaskTip2: '系统将自动为你测试模型',
    testSet: '测试集',
    temperature: '温度',
    maxTokens: '最大输出长度(Token)',
    topP: 'top_p',
    frequencyEnalty: '频率损失',
    presencePenalty: '状态惩罚',
    noBegin: '未开始',
    loading: '进行中',
    success: '成功',
    error: '失败',
    warning: '告警',
    needExecuteCron: '开关',
    enterprise: '企业',
    filePreview: '文件预览',
    modelMan: '模型管理',
  },
  system: {
    noFound: 'Page Not Found',
    noFoundTip: "We couldn't find the page you are looking for.",
    backGoHome: 'Back to Hom',
    searchPlaceHolder: 'Search',
    submit: 'Submit',
    cancel: 'Cancel',
  },
}
