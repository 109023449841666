export function assign(target, ...sources) {
  sources.forEach(source => {
    Object.keys(source).forEach(key => {
      if (!(key in target)) {
        target[key] = source[key]
      }
    })
  })

  return target
}

// 内容转化为文件下载
export const fileDownload = (file, fileName = '下载文件', options = {}) => {
  // 创建隐藏的可下载链接
  const eleLink = document.createElement('a')
  eleLink.download = fileName
  eleLink.style.display = 'none'

  // 字符内容转变成blob地址
  const blob = options ? new Blob([file], options) : new Blob([file])
  eleLink.href = URL.createObjectURL(blob)

  // 触发点击
  document.body.appendChild(eleLink)
  eleLink.click()

  // 然后移除
  document.body.removeChild(eleLink)
}
